import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
} from "@mui/material";
import { createContext, ReactNode, useContext, useState } from "react";

type DialogContextType = {
    show: () => void;
    hide: () => void;
    setDialogText: (text: string) => void;
    setDialogTitle: (title: string) => void;
    setDialogSeverity: (severity: SeverityType) => void;
    isOpen: boolean;
};

type SeverityType = "error" | "warning" | "info" | "success";

const defaultDialogContext = {
    show: () => {},
    hide: () => {},
    setDialogText: (text: string) => {},
    setDialogTitle: (title: string) => {},
    setDialogSeverity: (severity: SeverityType) => {},
    isOpen: false,
};

export const DialogContext =
    createContext<DialogContextType>(defaultDialogContext);

export const useDialog = () => useContext(DialogContext);

export const DialogContextProvider = ({
    children,
}: {
    children: ReactNode;
}) => {
    const [open, setOpen] = useState(false);
    const [title, setTitle] = useState("");
    const [text, setText] = useState("");
    const [severity, setSeverity] = useState("info");

    const hide = () => setOpen(false);
    const show = () => setOpen(true);
    const setDialogText = (title: string) => setText(title);
    const setDialogTitle = (title: string) => setTitle(title);
    const setDialogSeverity = (severity: SeverityType) => setSeverity(severity);

    // TODO: Add colors for severity to dialog

    return (
        <DialogContext.Provider
            value={{
                isOpen: open,
                hide,
                show,
                setDialogText,
                setDialogTitle,
                setDialogSeverity,
            }}
        >
            {children}
            <Dialog
                open={open}
                onClose={hide}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title" color={severity}>
                    {title}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText
                        id="alert-dialog-description"
                        color={severity}
                        sx={{ whiteSpace: "pre-line" }}
                    >
                        {text}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={hide}
                        autoFocus
                        sx={{ textTransform: "none" }}
                    >
                        Schließen
                    </Button>
                </DialogActions>
            </Dialog>
        </DialogContext.Provider>
    );
};
