import { Box, Typography } from "@mui/material";
import { Info } from "@mui/icons-material";
import { PropertyImageList } from "components/shared/form/ImageDisplay";
import { propertyFormStyles } from "components/styles";
import { useCallback } from "react";
import { usePropertyCreation } from "services/property-creation-context";

import { MultiImageUploader } from "components";
import { usePropertyEdit } from "services/property-edit-context";
import { useParams } from "react-router-dom";
import { theme } from "theme";

export const StepNineForm = () => {
    const { id } = useParams();
    const { property } = usePropertyEdit();

    return (
        <Box sx={propertyFormStyles.centeredContainer}>
            <Box
                sx={{
                    display: "flex",

                    flexDirection: "column",
                    border: "2px solid rgba(12,53,130,0.88)",
                    width: { xs: "100%", md: "800px" },
                    mb: "2rem",
                    padding: ".5rem .5rem",
                    gap: "1rem",
                }}
            >
                <Box
                    sx={{
                        display: "flex",
                        alignItems: "center",
                    }}
                >
                    <Info
                        sx={{
                            color: "rgba(12,53,130,0.88)",
                            width: "25px",
                            margin: "0 10px",
                        }}
                    />
                    <Typography sx={{ color: "rgba(12,53,130,0.88)" }}>
                        Aktuell werden nur Bilder im Format .jpg, .jpeg und .png
                        unterstützt.
                    </Typography>
                </Box>
                <Box
                    sx={{
                        display: "flex",
                        alignItems: "center",
                    }}
                >
                    <Info
                        sx={{
                            color: "rgba(12,53,130,0.88)",
                            width: "25px",
                            margin: "0 10px",
                        }}
                    />
                    <Typography sx={{ color: "rgba(12,53,130,0.88)" }}>
                        Bitte achten Sie darauf, dass die Bilder eine Dateigröße
                        von 5MB nicht überschreiten.
                    </Typography>
                </Box>
            </Box>

            {id ? (
                <PropertyImageList property={property} />
            ) : (
                <MultiImageUploader />
            )}
        </Box>
    );
};
